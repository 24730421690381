import { LocationStrategy } from '@angular/common';
import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { NzDrawerPlacement } from 'ng-zorro-antd/drawer';
import { Subscription } from 'rxjs';
import { CreateVideoManagerService } from 'src/app/Managers/create-video-manager.service';
import { ErrorService } from 'src/app/services/error.service';
import { cSessionService } from 'src/app/services/session.service';
import { ModifyVideo } from 'src/app/models/modify-video';
import { VideoManagerService } from 'src/app/Managers/video-manager.service';
import { Console } from 'console';
import { ModifyVideoManagerService } from 'src/app/Managers/modify-video-manager.service';
import { VideoService } from 'src/app/services/dataServices/video.service';
import { ProntoSettingService } from 'src/app/services/utils/pronto-setting.service';
import { VideoDataStore } from 'src/app/stores/video.store';

@Component({
  selector: 'app-mv-preview',
  templateUrl: './sap-preview.component.html',
  styleUrls: ['./sap-preview.component.scss']
})

export class SapPreviewComponent implements OnInit, OnDestroy {
  @ViewChild('videoPreview') videoPreview: ElementRef;
  @ViewChild('videoOverview') videoOverview: ElementRef;

  sUserEmail: string;
  sSessionId: string;
  sErrorMessage: any;
  oClosePopup: any = 0;
  bLoading: boolean = false;
  bResponse: boolean = false;
  sapVideo: boolean = true;
  bDisplayErrorMsg: boolean = false;
  hideButton = false;
  hideOverviewButton = false;
  videoPreviewPlay = false;
  videoOverviewPlay = false;
  constructor(private oSessionService: cSessionService, private errorService: ErrorService, public videoManger: CreateVideoManagerService, private videoListManger: VideoManagerService, private oRouter: Router, public modifyVideoObj: ModifyVideo, public modifyVideoManager: ModifyVideoManagerService,
    public dataservice: VideoService, private location: LocationStrategy,
    public videoStore: VideoDataStore,
    private prontoSettingService: ProntoSettingService) {
    history.pushState(null, null, window.location.href);
    this.location.onPopState(() => {
      if (!this.videoManger.isVideoGenerated) {
        console.log('Called and siabled ..');
        history.pushState(null, null, window.location.href);
        this.prontoSettingService.emitBackButtonDialogTextSubject({
          visible: true,
          text: 'Your work hasn`t been saved yet. If you leave now, any unsaved progress will be lost.',
        });
      }
    });
  }

  sub: Subscription;
  loadPlayerEvents() {
    const __this = this;
    setTimeout(() => {
      const video = document.querySelector("video");

      if (video) {
        video.onplay = (e) => {
          __this.hideButton = true;
        };
        video.onpause = (e) => {
          __this.hideButton = true;
        };
      }
    }, 1000);
    setTimeout(() => {
      const video2 = document.getElementById("videoOverview");

      if (video2) {
        video2.onplay = (e) => {
          __this.hideOverviewButton = true;
        };
        video2.onpause = (e) => {
          __this.hideOverviewButton = true;
        };
      }
    }, 1000);
  }
  ngOnInit(): void {
    this.videoManger.previewMode = true;
    // this.videoManger.isEditMode = true;
    this.sub = this.videoManger.submitForm.subscribe(c => {
      this.videoListManger.selectedVideo = this.videoManger.selectedVideo;
      this.oRouter.navigateByUrl('/my-videos/draftsVideo');
    });
    this.videoManger.mvPreviewMode = true;
    this.modifyVideoManager.isPreview = false;
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();


    this.bLoading = true;
    let video_id = this.videoManger.selectedVideo.fpath;
    if (!video_id || video_id == "") {
      video_id = this.videoManger.selectedVideo.video_id;
    }
    this.videoManger.generateVideo(this.sUserEmail, this.sSessionId, video_id, true).then(res => {
      console.log(" --- ", this.videoManger.selectedVideo.thumbnail);

      this.videoManger.generateVideoStatus(this.sUserEmail, this.sSessionId, this.videoManger.gv_task_id).then(rr => {
        console.log(" --222 - ", this.videoManger.selectedVideo.thumbnail);
        let batchUrl = this.videoManger.selectedVideo.fpath;
        this.dataservice.getThumbBatchSignedURL(this.sUserEmail, this.sSessionId, [batchUrl]).then((res) => {
          let output = res["output"];
          console.log(" -- sap output ", output);

          this.videoManger.selectedVideo.thumbnail = output[0].thumb_signed_url;

          this.videoManger.isEditMode = true;

          this.videoManger.selectedVideo = this.videoManger.selectedVideo;
          this.bLoading = false;
          this.bResponse = true;
          this.loadPlayerEvents();
        });

      }).catch(error => {
        this.sErrorMessage = `We're sorry, but we were unable to generate your video due to technical issues. Please try again later or contact our support team for assistance.`;
        this.errorService.handleError(this.sErrorMessage, 'Video Generation Failed', true, false, true);
        this.bLoading = false;
      })
    }).catch(err => {
      this.bLoading = false;

    });
  }

  ngOnDestroy() {

    this.videoManger.previewMode = false;
    if (this.sub) {
      this.sub.unsubscribe();
    }
    this.videoManger.isEditMode = true;
    this.modifyVideoObj.resetModifyVideo();
    this.videoManger.mvPreviewMode = false;

    this.prontoSettingService.emitBackButtonDialogTextSubject({
      visible: false,
      text: '',
    });

    // if (this.oTimeOut) clearTimeout(this.oTimeOut);
  }
  publishVideo() {
    this.videoManger.updateVideoStatus(this.sUserEmail, this.sSessionId, this.videoManger.selectedVideo.fpath, "published").then(res => {
      // this.videoListManger.selectedVideo = this.videoManger.selectedVideo;
      this.videoListManger.selectedVideo.public_url = res['public_url'];
      this.oRouter.navigate(['/published-video/timeline-view']);
    }).catch(err => {

      // this.sErrorMessage = 'An error occurred while publishing video. Please try again.';
      this.sErrorMessage = `We're sorry, but we were unable to generate your video due to technical issues. Please try again later or contact our support team for assistance.`;
      this.bDisplayErrorMsg = true;

      if (err.errorMessage.includes('Monthly quota')) {
        this.errorService.handleError(this.sErrorMessage, 'Monthly Quota Exceeded', true, false, true);
      }
    });
  }
  PreviewComponent_GetClickLocation(event) {
    this.oClosePopup = 0;
    if (event.clientX <= 65) {
      this.oClosePopup = 1;
      setTimeout(() => { this.oClosePopup = 0; }, 200);
    }
  }
  PreviewComponent_PlayVideo() {
    this.videoPreview.nativeElement.play();
    this.videoPreviewPlay = true;
  }
  OverviewComponent_PlayVideo() {
    this.videoOverview.nativeElement.play();
    this.videoOverviewPlay = true;
  }

  // modal scheduled video
  isVisible = false;
  showModal(): void {
    this.isVisible = true;
  }
  handleOk(): void {
    this.isVisible = true;
  }
  handleCancel(): void {
    this.isVisible = false;
  }
  visibleLocation = false;
  placementLocation: NzDrawerPlacement = 'right';
  openLocation(): void {
    if (this.bLoading == false && this.bDisplayErrorMsg == false) {
      console.log("vidres", this.videoManger.selectedVideo?.thumbnail);

      this.visibleLocation = true;
    }

  }
  closeLocation(): void {
    this.visibleLocation = false;
  }


}
