import { Component, HostListener, OnDestroy, OnInit } from "@angular/core";
import { FormBuilder, FormGroup } from "@angular/forms";
import { Router } from "@angular/router";
import { cSessionService } from "./../../../services/session.service";
import { cWebSocketService } from "./../../../services/websocket.service";
import { debounceTime, distinctUntilChanged } from "rxjs/operators";
import { cEditVideoService } from "./../../../services/edit-video.service";
import { cDataService } from "./../../../services/data.service";
import { cIntercomService } from "./../../../services/intercom.service";

import { ConfigManagerService } from "./../../../Managers/config-manager.service";
import { CreateVideoManagerService } from "./../../../Managers/create-video-manager.service";
import { OnboardingManagerService } from "./../../../Managers/onboarding-manager.service";
import { profile } from "./../../../models/onboarding";
import { SubscriptionplanManagerService } from "src/app/Managers/subscriptionplan-manager.service";
import { VideoManagerService } from "src/app/Managers/video-manager.service";
import { ProntoSettingService } from "src/app/services/utils/pronto-setting.service";
@Component({
  selector: "app-dashboard-myvideo",
  templateUrl: "./dashboard-myvideo.component.html",
  styleUrls: ["./dashboard-myvideo.component.scss"],
})
export class DashboardMyvideoComponent implements OnInit, OnDestroy {
  showMenu: string;
  sUserEmail: any;
  sSessionId: any;
  sIndustry: string = "";
  oMetaInfo: any;
  lIndustryKeyTermsArray: any = [];
  videoListForm: FormGroup;
  lPublishedVideos: any = [];
  bDashboardLoader: boolean = true;
  oSetTimeOut: any;
  sUserName: string = "";
  sSelectedSortingCategory: string = "Last Created";
  lSortBy: any = [
    { name: "Last Created", value: "created" },
    { name: "Name", value: "fpath" },
    { name: "Aspect Ratio", value: "aspect_ratio" },
    { name: 'FPS', value: 'fps' },
  ];
  fSearchVideo: FormGroup;
  oIntercom: any;
  intercomUserName: any;
  sUserCretaedAt: any;
  orderBySearchText = "created";
  session: cSessionService;

  public title: string = "";
  search_term: string = "";
  searchAspectRatio = 'all';
  videoAspectRatio = [
    { title: 'All', ratio: 'all', ratio_value: 'all', isSelected: true, class_name: 'all'},
    { title: 'Landscape', ratio: '16:9', ratio_value: '16x9', isSelected: false,  class_name: 'all'},
    { title: 'Square', ratio_value: '1x1', ratio: '1:1', isSelected: false,  class_name: 'sqaure'},
    { title: 'Story/Reels', ratio_value: '9x16', ratio: '9:16', isSelected: false,  class_name: 'reel'},
    { title: 'Portrait', ratio: '4:5',  ratio_value: '4x5', isSelected: false,  class_name: 'all'},
];
louderOrg = false;
  constructor(
    private oIntercomService: cIntercomService,
    public videoManager: CreateVideoManagerService,
    public configManager: ConfigManagerService,
    public onboardingManager: OnboardingManagerService,
    private oSessionService: cSessionService,
    private oRouter: Router,
    private oFormBuilder: FormBuilder,
    private oEditVideoService: cEditVideoService,
    private oDataService: cDataService,
    public planManager: SubscriptionplanManagerService, public videoManger: VideoManagerService,
    public pronto: ProntoSettingService,
  ) {
    this.session = oSessionService;
    this.oSessionService.cSessionService_SetGuestEmail('');
  }

  ngOnInit(): void {
    this.checkLouderOrgStatus();
    // console.log("logges user", this.onboardingManager.loggedUser);
    // if (this.onboardingManager.loggedUser.videoCount == 0) {
    //   this.oRouter.navigate(["dashboard/first-time-user"]);
    // }

    if(this.onboardingManager.profile.user_status === "confirmed_not_subscribed") {
      console.log("My viode confirmed but not subscribed ... ");
    }
    this.DashboardMyvideoComponent_InitializeJqueryCode();

    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();

    this.fSearchVideo = this.oFormBuilder.group({ title: [""] });

    if (this.sSessionId !== "" && this.sUserEmail !== "") {
      if (!this.onboardingManager.profile)
        this.getProfile(this.sUserEmail, this.sSessionId);
    }
  }

  async getProfile(email, session) {
    await this.onboardingManager
      .getUserProfile(email, session)
      .then((res: profile) => {
        this.configManager.getTemplates2(email, session, res.allowed_templates);
      });

    await this.onboardingManager
      .getUserVideosCount(email, session)
      .then((count) => {
        console.log(count);
        if (count == 0) {
          this.oRouter.navigateByUrl("/dashboard/first-time-user");
        }
      });

    this.configManager.getVoices(email, session).then();
  }
  ngOnDestroy() {
    this.oDataService.sSearchVideoByTitle.next({ title: "", type: "" });
  }
  DashboardMyVideosComponent_SetTimeOutForLoader() {
    this.oSetTimeOut = setTimeout(() => {
      this.bDashboardLoader = false;
    }, 10000);
  }

  createNewVideo() {
    this.videoManager.newVideo();
    this.oRouter.navigate(["wizard/overview"]);
  }
  createNewModifiedVideo() {
    this.videoManager.newVideo();
    this.oRouter.navigate(["mv"]);
  }

  DashboardMyvideoComponent_HideLoader(bEmittedLoadingController: boolean) {
    this.bDashboardLoader = bEmittedLoadingController;
    clearTimeout(this.oSetTimeOut);
  }


  DashboardMyvideoComponent_SortVideos(oIncommingSortingFilter: any) {
    this.sSelectedSortingCategory = oIncommingSortingFilter.name;
    // console.log("Clicked .. search ", this.sSelectedSortingCategory );
    if (oIncommingSortingFilter.value == 'fpath') {
      this.orderBySearchText = 'video.title';
    }
    if (oIncommingSortingFilter.value == 'created') {
      this.orderBySearchText = 'created';
    }
    if (oIncommingSortingFilter.value == 'aspect_ratio') {
      this.orderBySearchText = 'video.aspect_ratio';
    }

    // console.log(this.orderBySearchText);
  }
  DashboardMyvideoComponent_SearchVideo() {
    this.fSearchVideo
      .get("title")
      .valueChanges.pipe(debounceTime(1000), distinctUntilChanged())
      .subscribe((data) => {
        this.oDataService.sSearchVideoByTitle.next({
          title: data,
          type: "published",
        });
        this.bDashboardLoader = true;
        this.DashboardMyVideosComponent_SetTimeOutForLoader();
        // console.log("Search video : ==> ", data);
      });
  }
  // Fetch Voice OverList Section

  DashboardComponent_HandleVoiceoverListResponse = (InComingMessage) => {
    console.log(
      "ContentComponent_HandleVoiceoverListResponse => ",
      JSON.parse(InComingMessage)
    );
    if (JSON.parse(InComingMessage)["errorMessage"]) {
      //alert('Error ' + JSON.parse(InComingMessage)['errorMessage']);
      // this.responseStatus = 'Error ' + JSON.parse(InComingMessage)['errorMessage'];
    } else {
      //convert nested object into list
      let oGetVoiceOverObject = JSON.parse(InComingMessage);
      let oUserVoiceOverList: any = Object.entries(oGetVoiceOverObject);
      let sessionVoiceoverList = [];
      oUserVoiceOverList.forEach((element) => {
        let displaytext = element[0].split(/(\d+)/);
        let name = displaytext[0] + " " + displaytext[1];
        let sample_voice = element[1].sample_voice;
        let cps = element[1].cps;
        let gender = element[1].gender;
        let voice_name = element[1].voice_name;
        sessionVoiceoverList.push({
          name: name,
          value: element[0],
          sample_voice: sample_voice,
          cps: cps,
          gender: gender,
          voice_name: voice_name,
        });
      });
      this.oSessionService.cSessionService_SetUserVoiceOverList(
        sessionVoiceoverList
      );
    }
  };

  DashboardMyvideoComponent_InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `jQuery('.button-gl').on('click',function(e) {
      jQuery('.button-gl').removeClass('active');
      jQuery(this).addClass('active');

  if (jQuery(this).hasClass('grid')) {
      jQuery('#container-view div.row').removeClass('list').addClass('grid');
  }
  else if(jQuery(this).hasClass('list')) {
      jQuery('#container-view div.row').removeClass('grid').addClass('list');
  }
});

jQuery(function(){
jQuery('.showSingle').click(function(){
  jQuery('.targetDiv').hide('.cnt');
  jQuery('#div'+$(this).attr('target')).slideToggle();

});
});

$(document).ready(function(){
  var submitIcon = $('.searchbox-icon');
  var inputBox = $('.searchbox-input');
  var searchBox = $('.searchbox');
  var isOpen = false;
  submitIcon.click(function(){
      if(isOpen == false){
          searchBox.addClass('searchbox-open');
          inputBox.focus();
          isOpen = true;
      } else {
          searchBox.removeClass('searchbox-open');
          inputBox.focusout();
          isOpen = false;
      }
  });
   submitIcon.mouseup(function(){
          return false;
      });
  searchBox.mouseup(function(){
          return false;
      });
  $(document).mouseup(function(){
          if(isOpen == true){
              $('.searchbox-icon').css('display','block');
              submitIcon.click();
          }
      });
});
  function buttonUp(){
      var inputVal = $('.searchbox-input').val();
      inputVal = $.trim(inputVal).length;
      if( inputVal !== 0){
          $('.searchbox-icon').css('display','none');
      } else {
          $('.searchbox-input').val('');
          $('.searchbox-icon').css('display','block');
      }
  }`;
    document.body.appendChild(chatScript);
  }
  gridView() {
    this.session.dashboardVideoGridView = true;
    this.session.dashboardVideoListView = false;
    this.pronto.videoDataView.next({ data : this.session.dashboardVideoListView});
    console.log("gird", this.session.dashboardVideoGridView);
  }
  listView() {
    this.session.dashboardVideoListView = true;
    this.session.dashboardVideoGridView = false;
    this.pronto.videoDataView.next({ data : this.session.dashboardVideoListView});
    console.log("list", this.session.dashboardVideoListView);
  }
  SearchVideo() {
    this.title = this.title.replace(/^\s+|\s+$/g, '');
    this.videoManger.search.emit(this.title);
    this.search_term = this.title;
  }

  clearSearchTerm() {
    this.search_term = "";
    this.title = "";
    this.videoManger.search.emit(this.title);
  }
  selectAspectRatio(ratio : string) {
    this.searchAspectRatio = ratio;
  }

  checkLouderOrgStatus() {
        
    if( this.onboardingManager.profile.organization_id == "65d5d0c9c4a3359b9fcf9e9f"){
        this.louderOrg = true;
         }
    if(!this.louderOrg) {
        this.lSortBy = this.lSortBy.filter(item => item.value !== 'fps');
    }
}
}
