import { Component, OnDestroy, OnInit,AfterContentInit } from '@angular/core';
import { Router } from '@angular/router';
import { ConfigManagerService } from './../../../Managers/config-manager.service';
import { CreateVideoManagerService } from './../../../Managers/create-video-manager.service';
import { VideoManagerService } from './../../../Managers/video-manager.service';
import { cDataService } from './../../../services/data.service';
import { cSessionService } from './../../../services/session.service';
import { cWebSocketService } from './../../../services/websocket.service';
import { environment } from './../../../../environments/environment';
import { OnboardingManagerService } from 'src/app/Managers/onboarding-manager.service';

@Component({
  selector: 'app-published-videos',
  templateUrl: './published-videos.component.html',
  styleUrls: ['./published-videos.component.scss']
})
export class PublishedVideosComponent implements OnInit,OnDestroy {


  bPublishLoader : boolean = true;
  oSetTimeOut: any;
  sVideoToSearch : string;
  sSessionId:string;
  sUserEmail:string;
public title:string="";
search_term: string="";
  constructor(private oSessionService : cSessionService,public videoManager:VideoManagerService,public configManager:ConfigManagerService, private oRouter : Router, private oDataService : cDataService,private createVideoManager:CreateVideoManagerService,
    public onboardingManager: OnboardingManagerService,) {}
  ngOnDestroy()
  {
    this.oDataService.sSearchVideoByTitle.next({title : "",type : ""});
  }


  ngOnInit()
  {
    this.sSessionId = this.oSessionService.SessionService_GetSessionId();
    this.sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    this.oSessionService.dashboardVideoListView = false;
    this.oSessionService.dashboardVideoGridView = true;
  }

  PublishedVideosComponent_NavigateToCreateVideo()
  {
    this.createVideoManager.newVideo();
    this.oRouter.navigate(['wizard/overview']);
  }





  SearchVideo()
  {
    this.title = this.title.replace(/^\s+|\s+$/g, '');
this.videoManager.search.emit(this.title);
this.search_term = this.title;
}


clearSearchTerm() {
this.search_term = "";
this.title = "";
this.videoManager.search.emit(this.title);
}

  DashboardMyvideoComponent_GetProfileData()
  {
   let sSessionId = this.oSessionService.SessionService_GetSessionId();
    let sUserEmail = this.oSessionService.cSessionService_GetUserEmail();
    const oGetUserProfile =
    {
      session_id:  sSessionId,
      email:  sUserEmail
    }
    console.log("DashboardMyvideoComponent_GetProfileData : Profile Request Object ==> ",oGetUserProfile);
   }

  DashboardMyvideoComponent_HandleUserProfileResponse = async (InComingMessage) =>
  {
    if (InComingMessage['errorMessage'])
    {
      console.error('DashboardMyvideoComponent_HandleUserProfileResponse : Profile Response Error ==> ', InComingMessage);

    }
    else
    {
      let oResponse = InComingMessage;
      console.log('DashboardMyvideoComponent_HandleUserProfileResponse : Profile Response +==> ', oResponse);


if(oResponse.localization_enabled != undefined)
{
  console.log(this.configManager.bDisplayLocales )
  this.configManager.bDisplayLocales = oResponse.localization_enabled;
      console.log(this.configManager.bDisplayLocales )
}

    }
  }





  InitializeJqueryCode() {
    let chatScript = document.createElement("script");
    chatScript.type = "text/javascript";
    chatScript.async = true;
    chatScript.text = `$(document).ready(function () {
      function toggleSidebar(side) {
        if (side !== "left" && side !== "right") {
          return false;
        }
        var left = $("#sidebar-left"),
          right = $("#sidebar-right"),
          content = $("#content"),
          openSidebarsCount = 0,
          contentClass = "";

        // toggle sidebar
        if (side === "left") {
          left.toggleClass("collapsed");
        } else if (side === "right") {
          right.toggleClass("collapsed");
        }

        // determine number of open sidebars
        if (!left.hasClass("collapsed")) {
          openSidebarsCount += 1;
        }

        if (!right.hasClass("collapsed")) {
          openSidebarsCount += 1;
        }

        // determine appropriate content class
        if (openSidebarsCount === 0) {
          contentClass = "col-md-12";
        } else if (openSidebarsCount === 1) {
          contentClass = "col-md-11 pleft";

        } else {
          contentClass = "col-md-9";
        }

        // apply class to content
        content.removeClass("col-md-12 col-md-9 col-md-11 pleft")
             .addClass(contentClass);
      }

      $(".toggle-sidebar-left").click(function () {
        toggleSidebar("left");

        return false;
      });
      $(".toggle-sidebar-right").click(function () {
        toggleSidebar("right");

        return false;
      });
    });
     `;
    document.body.appendChild(chatScript);
  }
  createNewModifiedVideo() {
    this.createVideoManager.newVideo();
    this.oRouter.navigate(["mv"]);
  }

}
