import { VideoDataStore } from 'src/app/stores/video.store';
import { EventEmitter, Injectable } from '@angular/core';
import { block_args, duration, video } from '../models/video';
import { interval } from 'rxjs';
import { takeWhile } from 'rxjs/operators';
import { VideoService } from '../services/dataServices/video.service';
import { LoaderService } from '../services/loader.service';
import { template, templatesCategory } from '../models/template';
import { ConfigManagerService } from './config-manager.service';
import { OnboardingManagerService } from './onboarding-manager.service';
import { ErrorService } from '../services/error.service';
import { VideoManagerService } from './video-manager.service';
import { cSessionService } from '../services/session.service';
import { Router } from '@angular/router';
import { brand } from '../models/admin';

@Injectable({
    providedIn: 'root',
})
export class CreateVideoManagerService {
    constructor(
        public dataservice: VideoService,
        public loader: LoaderService,
        public configManager: ConfigManagerService,
        public onboardManger: OnboardingManagerService,
        public errorService: ErrorService,
        public videoManager: VideoManagerService,
        public videoStore: VideoDataStore,
        public oSessionService: cSessionService,
        private oRouter: Router,
    ) { }
    public submitForm: EventEmitter<string> = new EventEmitter();
    public previousSubmit: EventEmitter<number> = new EventEmitter();
    public changeHeaderNavByForce: EventEmitter<{ type?: string; action?: string }> = new EventEmitter();
    public clearGenerationInterval = false;
    public previewMode: boolean = false;
    public mvPreviewMode: boolean = false;
    public selectedVideo: video;
    public selectedTemplate: template;
    public selectedCategory: templatesCategory;
    public selectedDuration: number;
    public isEditMode: boolean = false;
    public isFirstTime: boolean = false;
    public durations: duration[] = [];
    public duration_fine_print_text_List: duration[] = [];
    public blocksconfig: block_args[] = [];
    indexMultiLiner = [];
    showAddressTabOnCreateVideo: boolean = true;
    wizardCurrentTab = '';
    videoBrand: brand;
    fontDefaultColor = '#ffffff';
    themeDefaultColor = '#ffffff';
    templateVideoLimit : any;
    templateVideoDurationLimit = 0;
    public setEditVideo(email, session_id, video: video) {
        this.selectedVideo = video;
        this.isEditMode = true;
        this.isFirstTime = false;

        this.selectedVideo.email = email;
        this.selectedVideo.session_id = session_id;

        this.selectedCategory = this.configManager?.Templates.filter((c) => c.category === this.selectedVideo?.template_category)[0];
        this.selectedTemplate = this.selectedCategory?.templates.filter((c) => c.template_name === video.template_name)[0];

        if (this.selectedTemplate) {
            this.changeTemplate(this.selectedTemplate);
        }
        this.getsignedThumbandURLForBlock(email, session_id);
    }

    public setMvEditVideo(email, session_id, video: video) {
        this.selectedVideo = video;
        this.isEditMode = true;
        this.isFirstTime = false;

        this.selectedVideo.email = email;
        this.selectedVideo.session_id = session_id;

        this.getsignedThumbandURLForBlock(email, session_id);
    }

    public newVideo() {
        this.selectedVideo = undefined;
        this.isEditMode = false;
        this.isFirstTime = false;
    }
    public firstTimeVideo() {
        this.selectedVideo = undefined;
        this.isEditMode = false;
        this.isFirstTime = true;
    }

    getTemplateForVideo(name) { }
    public changeTemplate(template: template) {
        if (template) {
            // console.log("change template called", template);
            this.durations = [];
            this.selectedTemplate = template;

            let numSize = 0;
            // console.log('Selected TEmplate ', template);
            this.selectedVideo.video_preview_url = template.video_preview_url ? template.video_preview_url : template.preview_url;

            if (template) {
                this.indexMultiLiner = [];
                this.duration_fine_print_text_List = [];
                this.dataservice
                    .getTemplateContent(template.template_name, 'text', this.selectedVideo?.duration)
                    .then((res) => {
                        let texts = res['template_data'];

                        let prevText: string[] = [];
                        this.durations = [];

                        texts.map((txt, index) => {
                            if (txt.line_count && txt.line_count > 1) {
                                this.indexMultiLiner.push(index);
                            }
                        });

                        // console.log("Multi Liner ", this.indexMultiLiner);
                        texts.map((txt, index) => {
                            if (txt.text != '_address_' && txt.text != '_url_' && txt.text != '_phone_') {
                                // console.log("Tempate Text", txt.text , txt);
                                // this.durations.forEach((d) => {
                                //   if (d.placehoder == txt.text) {
                                //     if (txt.ts[0] <= d.duration) {
                                //       d.duration = txt.ts[0];
                                //     }
                                //   }
                                // });

                                if (!prevText.includes(txt.text)) {
                                    prevText.push(txt.text);
                                    this.createDurationObject(txt, template, index, index);
                                }
                            }
                        });
                        this.arrangePartOfContent();
                        this.durations.sort((a: any, b: any) => a.duration - b.duration);
                        // console.log(" this.durations ", this.durations);
                    })
                    .catch((err) => {
                        // console.log(err);
                        this.errorService.handleError(err.error ? err.error : err, 'Content Generation Failed', true);
                    });
            }
        }

    }

    arrangePartOfContent() {
        this.durations.map((dur) => {
            if (dur.partOfId == dur.oid) {
                dur.fillChildAutoType = true;
            }
        });
    }
    createDurationObject(txt, template, index, orgIndex, multiField = false) {
        let dur = new duration();
        dur.id = template.template_name + index;
        dur.oid = txt.id;
        dur.duration = txt.ts[0];
        dur.originalSortOrder = orgIndex;
        dur.pos = txt.pos;
        dur.size = txt.size;
        dur.index = index;
        dur.parentIndex = txt.parentIndex ? txt.parentIndex : undefined;
        dur.max_chars = txt.max_chars ? txt.max_chars : 40;
        dur.isMultiLiner = txt.line_count != undefined && txt.line_count > 1 ? true : false;
        dur.content = txt.content;
        dur.visible = txt.parts && txt.parts.length > 0 ? true : false;
        dur.partOfId = txt.partOfId ? txt.partOfId : undefined;
        dur.placeholder = 'Enter text';
        if (dur.isMultiLiner) {
            // console.log(Array.isArray(txt.placeholder), this.indexMultiLiner.indexOf(index));
            if (this.indexMultiLiner.indexOf(index) > -1) {
                let loopIndex = 0;
                for (let i = 0; i < txt.line_count; i++) {
                    const txtObj = {
                        content: txt.content,
                        id: txt.id + '' + i,
                        oid: txt.id,
                        placeholder:
                            txt.placeholder && Array.isArray(txt.placeholder)
                                ? txt.placeholder.length >= i && txt.placeholder[i]
                                    ? txt.placeholder[i]
                                    : ''
                                : txt.placeholder,
                        // placeholder: "text here",
                        stretch: '',
                        isMultiLiner: false,
                        max_chars: txt.max_chars ? txt.max_chars : 40,
                        parentIndex: txt.id,
                        text: '_txt1_',
                        index: index,
                        pos: txt.pos,
                        size: txt.size,
                        ts: txt.ts,
                        parts: txt.parts,
                        partOfId: txt.parts && txt.parts.length >= i ? txt.parts[i] : undefined,
                    };
                    txtObj.placeholder = txtObj.placeholder;
                    this.createDurationObject(txtObj, template, index + '-' + i, index, multiField);
                }
            }
        } else {
            if (txt.placeholder) {
                dur.placeholder = txt.placeholder;
            }
        }

        dur.textlist.push({ placeholder: dur.placeholder, value: '' });
        if (!txt.content || dur.content != 'auto_text') {
            this.durations.push(dur);
        } else {
            this.duration_fine_print_text_List.push(dur);
        }
    }

    public createVideo(videoObj: video) {
        const video: video = videoObj;
        if (video.brand_id && video.brand_id == '') {
            delete video.brand_id;
        }

        if (!video.font_color || video.font_color == '') {
            delete video.font_color;
        }

        this.loader.showLoader();

        if (!video.session_id || !video.email) {
            const session = this.oSessionService.SessionService_GetSessionId();
            const email = this.oSessionService.cSessionService_GetUserEmail();
            video.session_id = session;
            video.email = email;
        }

        if (video.template_name == undefined || video.template_name == '' || video.template_name.toLowerCase() != 'sap') {
            delete video.sap_video_duration;
        }
        if (this.onboardManger.profile.plan != 'enterprise') {
            delete video.brand_id;
        }
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .createVideo(video)
                .then((res) => {
                    this.loader.hideLoader();
                    resolve(res);
                })
                .catch((err) => {
                    // console.log('Error creating video ', err);
                    this.errorService.handleError(err.errorMessage, err.errorType, true);
                    this.loader.hideLoader();
                    reject(err);
                });
        });

        return promise;
    }

    public DirectLocalizeVideo(video: video) {
        this.loader.showLoader();

        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .direct_localized_video(video)
                .then((res) => {
                    this.loader.hideLoader();
                    resolve(res);
                })
                .catch((err) => {
                    this.errorService.handleError(err.errorMessage, err.errorType, true);
                    this.loader.hideLoader();
                    reject(err);
                });
        });

        return promise;
    }

    updateVideoStatus(email, session_id, video_id, status): Promise<unknown> {
        this.loader.showLoader();
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .updateStatus(email, session_id, video_id, status)
                .then((res) => {
                    this.loader.hideLoader();
                    resolve(res);
                })
                .catch((err) => {
                    this.loader.hideLoader();
                    if (err.errorMessage.includes('Monthly quota')) {
                        this.errorService.handleError(err.errorMessage, 'Monthly Quota Exceeded', true, false, true);
                    }
                    reject(err);
                });
        });
        return promise;
    }
    getVideo(email, session_id, video_id): Promise<unknown> {
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .getVideo(email, session_id, video_id)
                .then((res) => {
                    resolve(res);
                })
                .catch((err) => {
                    reject(err);
                });
        });
        return promise;
    }
    generateVideo(email, session_id, video_id, loadVideo = true): Promise<unknown> {
        let promise = new Promise((resolve, reject) => {
            this.dataservice
                .generateVideo2(email, session_id, video_id)
                .then(async (res: any) => {
                    this.videoStore.clearGenerationInterval = false;
                    if (loadVideo) {
                        // console.log(" LOAD VIDEO CALLED ....");
                        await this.getVideo(email, session_id, video_id).then(async (resi: any) => {
                            this.selectedVideo = resi['video'] as video;
                            // console.log("GET SIGNED URL ...");
                            this.getsignedThumbandURLForBlock(email, session_id);
                        });
                    }
                    this.gv_task_id = res['gv_task_id'];
                    resolve(res);
                })
                .catch((err) => {
                    // console.log("Error messages ");
                    let sErrorMessage;
                    if(err.errorMessage && err.errorMessage.indexOf("No clips found") > -1 ){
                        let sErrorMessage = err.errorMessage;
                        let sErrorMessageTitle = 'Video Generation Failed';
                        // console.log('process failed ', err);
                        this.errorService.handleError(sErrorMessage, sErrorMessageTitle, true);
                        // this.oRouter.navigateByUrl('/dashboard/myvideos');
                    } else {
                        if (err.errorMessage.includes("Our AI couldn't find relevant clips")) {
                            sErrorMessage = err.errorMessage;
                        }
                        else {
                            sErrorMessage = `We're sorry, but we were unable to generate your video due to technical issues. Please try again later or contact our support team for assistance.`;
                        }
                         let sErrorMessageTitle = 'Video Generation Failed';
                        // console.log('process failed ', err);
                        this.errorService.handleError(sErrorMessage, sErrorMessageTitle, true);
                        // this.oRouter.navigateByUrl('/dashboard/myvideos');
                    }

                    reject(err);
                });
        });
        return promise;
    }
    public gv_task_id: string;
    public isVideoGenerated: boolean = false;
    generateVideoStatus(email, session_id, gv_task_id): Promise<unknown> {
        if (this.isVideoGenerated) {
            this.isVideoGenerated = false;
        }
        // console.log("  .....Check Video Generation TASK IDS");
        let promise = new Promise((resolve, reject) => {
            let generationInterval = interval(4000)
                .pipe(takeWhile(() => !this.isVideoGenerated))
                .subscribe(() => {
                    if (this.videoStore.clearGenerationInterval) {
                        generationInterval.unsubscribe();
                        this.videoStore.clearGenerationInterval = false;
                    }
                    this.dataservice
                        .generatedVideoStatus(email, session_id, gv_task_id)
                        .then(async (data: any) => {
                            if (data['task_status'] == 'success') {
                                await this.getVideo(email, session_id, this.selectedVideo.fpath).then(async (resi: any) => {
                                    this.isVideoGenerated = true;
                                    this.selectedVideo = resi['video'] as video;

                                    this.getsignedThumbandURLForBlock(email, session_id);
                                });

                                resolve(data);
                            } else {
                                this.isVideoGenerated = false;
                            }
                        })
                        .catch((err) => {
                            this.isVideoGenerated = true;
                            reject(err);
                        });
                });
        });

        return promise;
    }

    getsignedThumbandURLForBlock(email, session_id) {
        if (this.selectedVideo.music_file) {
            // console.log("musicfile", this.selectedVideo.music_file);
            this.dataservice
                .getSignedURL(email, session_id, this.selectedVideo.music_file)
                .then((res) => {
                    this.selectedVideo.music_file_url = res['url'];
                })
                .catch((err) => { });
        }
        if (this.selectedVideo.voice_file) {
            // console.log("voice_file", this.selectedVideo.voice_file);
            this.dataservice
                .getSignedURL(email, session_id, this.selectedVideo.voice_file)
                .then((res) => {
                    this.selectedVideo.voice_file_url = res['url'];
                })
                .catch((err) => { });
        }

        if (this.selectedVideo.vo_text_file) {
            // console.log("Checking voice Text File ...");
            // console.log("vo_text_file", this.selectedVideo.vo_text_file);
            this.dataservice
                .getSignedURL(email, session_id, this.selectedVideo.vo_text_file)
                .then((res) => {
                    this.selectedVideo.voice_file_url = res['url'];
                })
                .catch((err) => { });
        }

        if (this.selectedVideo.logo_img) {
            this.dataservice
                .getSignedURL(email, session_id, this.selectedVideo.logo_img)
                .then((res) => {
                    this.selectedVideo.logo_img_url = res['url'];
                })
                .catch((err) => { });
        }
        if (this.selectedVideo.second_logo_img) {
            // console.log("Second logo Image", this.selectedVideo.second_logo_img);
            this.dataservice
                .getSignedURL(email, session_id, this.selectedVideo.second_logo_img)
                .then((res) => {
                    this.selectedVideo.second_logo_img_url = res['url'];
                })
                .catch((err) => { });
        }

        if (this.selectedVideo.fpath) {
            // console.log("Vidoe fpath", this.selectedVideo.fpath);
            this.dataservice
                .getSignedURL(email, session_id, this.selectedVideo.fpath)
                .then((res) => {
                    this.selectedVideo.video_url = res['url'];
                    this.videoManager.selectedVideo.video_url = res['url'];
                    // console.log( "this.selectedVideo.video_url ", this.selectedVideo.video_url = res["url"]);
                    // console.log( " ---- this.videoManager", this.videoManager);
                })
                .catch((err) => { });
        }

        let batchUrl: string[] = [];
        if (this.selectedVideo.sap_video_id) batchUrl.push(this.selectedVideo.sap_video_id);
        this.selectedVideo.template_info?.contents
            ?.filter((c) => c.content == 'clip')
            .forEach((ele) => {
                if (ele.source) {
                    batchUrl.push(ele.source);
                    ele.loadingUrl = true;
                }
            });

        this.selectedVideo.sap_config?.clips?.forEach((ele) => {
            if (ele.video_id) {
                batchUrl.push(ele.video_id);
                ele.loadingURL = true;
            }
        });


        this.dataservice.getThumbBatchSignedURL(email, session_id, batchUrl).then((res) => {
            let output = res['output'];
            // console.log("output", output);

            if (this.selectedVideo.sap_video_id) {

                this.selectedVideo.sap_video_url_thumb = output.filter(
                    (a) => a.fpath == this.selectedVideo.sap_video_id,
                )[0]?.thumb_signed_url;

                // this.selectedVideo.thumbnail =  this.selectedVideo.sap_video_url_thumb;
                // console.log(" this.selectedVideo.sap_video_url_thumb", this.selectedVideo.sap_video_url_thumb);
            }
            this.selectedVideo.sap_config?.clips?.forEach((ele) => {
                if (ele.video_id) {
                    ele.video_thumbnail = output.filter((a) => a.fpath == ele.video_id)[0]?.thumb_signed_url;
                    setTimeout(() => {
                        ele.loadingURL = false;
                    }, 2000);
                }
            });

            this.selectedVideo.template_info?.contents
                ?.filter((c) => c.content == 'clip')
                .forEach((ele) => {
                    ele.loadingThumbnail = false;
                    ele.thumbnail = output.filter((a) => a.fpath == ele.source)[0]?.thumb_signed_url;
                    setTimeout(() => {
                        ele.loadingThumbnail = false;
                    }, 2000);
                });
        });

        this.dataservice.getBatchSignedURL(email, session_id, batchUrl).then((res) => {
            let output = res['output'];
            // console.log("output thumb here ...", output);
            this.selectedVideo.thumbnail = output.filter((a) => a.thumb == this.selectedVideo.fpath)[0]?.thumb_signed_url;
            this.selectedVideo.thumbnail = output.filter((a) => a.fpath == this.selectedVideo.fpath)[0]?.thumb_signed_url;

            if (this.selectedVideo.sap_video_id) {
                this.selectedVideo.sap_video_url = output.filter((a) => a[this.selectedVideo.sap_video_id])[0][
                    this.selectedVideo.sap_video_id
                ];
            }

            this.selectedVideo.sap_config?.clips?.forEach((ele) => {
                if (ele.video_id) {
                    ele.video_url = output.filter((a) => a[ele.video_id])[0][ele.video_id];
                    setTimeout(() => {
                        ele.loadingURL = false;
                    }, 2000);
                }
            });

            this.selectedVideo.template_info?.contents
                ?.filter((c) => c.content == 'clip')
                .forEach((ele) => {
                    ele.loadingUrl = false;
                    ele.url = output.filter((a) => a[ele.source])[0][ele.source];
                    setTimeout(() => {
                        ele.loadingUrl = false;
                    }, 2000);
                });
        });
    }

    // asyn function to load media signed url of a video
    async intiateVideoDataLoading(email, session_id, video: video): Promise<any> {
        this.selectedVideo = video;
        this.isEditMode = true;
        this.isFirstTime = false;

        this.selectedVideo.email = email;
        this.selectedVideo.session_id = session_id;

        this.selectedCategory = this.configManager?.Templates.filter((c) => c.category === this.selectedVideo?.template_category)[0];
        this.selectedTemplate = this.selectedCategory?.templates.filter((c) => c.template_name === video.template_name)[0];

        if (this.selectedTemplate) {
            this.changeTemplate(this.selectedTemplate);
        }
        return new Promise(async (resolve, reject) => {
            try {
                // console.log("**** load Video thum info ...", video);
                if (this.selectedVideo.logo_img) {
                    await this.dataservice
                        .getSignedURL(email, session_id, this.selectedVideo.logo_img)
                        .then((res) => {
                            this.selectedVideo.logo_img_url = res['url'];
                        })
                        .catch((err) => { });
                }
                if (this.selectedVideo.second_logo_img) {
                    // console.log("Second logo Image", this.selectedVideo.second_logo_img);
                    await this.dataservice
                        .getSignedURL(email, session_id, this.selectedVideo.second_logo_img)
                        .then((res) => {
                            this.selectedVideo.second_logo_img_url = res['url'];
                        })
                        .catch((err) => { });
                }

                if (this.selectedVideo.fpath) {
                    // console.log("Vidoe fpath", this.selectedVideo.fpath);
                    this.dataservice
                        .getSignedURL(email, session_id, this.selectedVideo.fpath)
                        .then((res) => {
                            this.selectedVideo.video_url = res['url'];
                            this.videoManager.selectedVideo.video_url = res['url'];
                            // console.log( "this.selectedVideo.video_url ", this.selectedVideo.video_url = res["url"]);
                            // console.log( " ---- this.videoManager", this.videoManager);
                        })
                        .catch((err) => { });
                }
                if (this.selectedVideo.fpath) {
                    this.dataservice.getThumbBatchSignedURL(email, session_id, [this.selectedVideo.fpath]).then((res) => {
                        let output = res['output'];

                        this.selectedVideo.thumbnail = output.filter((a) => a.fpath == this.selectedVideo.fpath)[0]?.thumb_signed_url;
                        this.selectedVideo.thumbnail = output.filter((a) => a.fpath == this.selectedVideo.fpath)[0]?.thumb_signed_url;


                        // console.log(" thumb of the movie here...", this.selectedVideo.thumbnail)
                    });
                }
                if (this.selectedVideo.music_file) {
                    // console.log( " ****** Load music data");
                    // console.log("musicfile", this.selectedVideo.music_file);
                    await this.dataservice
                        .getSignedURL(email, session_id, this.selectedVideo.music_file)
                        .then((res) => {
                            this.selectedVideo.music_file_url = res['url'];
                        })
                        .catch((err) => { });
                }

                if (this.selectedVideo.voice_file) {
                    // console.log("voice_file", this.selectedVideo.voice_file);
                    await this.dataservice
                        .getSignedURL(email, session_id, this.selectedVideo.voice_file)
                        .then((res) => {
                            // console.log("%cLoading voice over data ...", res)
                            this.selectedVideo.voice_file_url = res['url'];
                        })
                        .catch((err) => { });
                }
                if (this.selectedVideo.vo_file) {
                    await this.dataservice
                        .getSignedURL(email, session_id, this.selectedVideo.vo_file)
                        .then((res) => {
                            this.selectedVideo.voice_file_url = res['url'];
                        })
                        .catch((err) => { });
                }

                if (this.selectedVideo.vo_text_file) {
                    // console.log( " ******  Load Voice File");
                    // console.log("vo_text_file", this.selectedVideo.vo_text_file);
                    await this.dataservice
                        .getSignedURL(email, session_id, this.selectedVideo.vo_text_file)
                        .then((res) => {
                            this.selectedVideo.voice_file_url = res['url'];
                        })
                        .catch((err) => { });
                }

                let batchUrl: string[] = [];
                if (this.selectedVideo.sap_video_id) batchUrl.push(this.selectedVideo.sap_video_id);
                this.selectedVideo.template_info?.contents
                    ?.filter((c) => c.content == 'clip')
                    .forEach((ele) => {
                        if (ele.source) {
                            batchUrl.push(ele.source);
                            ele.loadingUrl = true;
                        }
                    });

                this.selectedVideo.sap_config?.clips?.forEach((ele) => {
                    if (ele.video_id) {
                        batchUrl.push(ele.video_id);
                        ele.loadingURL = true;
                    }
                });

                this.dataservice.getThumbBatchSignedURL(email, session_id, batchUrl).then((res) => {
                    // console.log( " ****** Load batched signed url of sap config", this.selectedVideo.sap_video_id);
                    let output = res['output'];
                    // console.log("output", output);

                    if (this.selectedVideo.sap_video_id) {
                        this.selectedVideo.sap_video_url_thumb = output.filter(
                            (a) => a.fpath == this.selectedVideo.sap_video_id,
                        )[0]?.thumb_signed_url;
                        this.selectedVideo.thumbnail = output.filter((a) => a.fpath == this.selectedVideo.sap_video_id)[0]?.thumb_signed_url;
                    }
                    this.selectedVideo.sap_config?.clips?.forEach((ele) => {
                        if (ele.video_id) {
                            ele.video_thumbnail = output.filter((a) => a.fpath == ele.video_id)[0]?.thumb_signed_url;
                            setTimeout(() => {
                                ele.loadingURL = false;
                            }, 2000);
                        }
                    });

                    this.selectedVideo.template_info?.contents
                        ?.filter((c) => c.content == 'clip')
                        .forEach((ele) => {
                            ele.loadingThumbnail = false;
                            ele.thumbnail = output.filter((a) => a.fpath == ele.source)[0]?.thumb_signed_url;
                            setTimeout(() => {
                                ele.loadingThumbnail = false;
                            }, 2000);
                        });
                });

                await this.dataservice.getBatchSignedURL(email, session_id, batchUrl).then((res) => {
                    let output = res['output'];
                    // console.log("output", output);

                    if (this.selectedVideo.sap_video_id) {
                        this.selectedVideo.sap_video_url = output.filter((a) => a[this.selectedVideo.sap_video_id])[0][
                            this.selectedVideo.sap_video_id
                        ];
                    }

                    this.selectedVideo.sap_config?.clips?.forEach((ele) => {
                        if (ele.video_id) {
                            ele.video_url = output.filter((a) => a[ele.video_id])[0][ele.video_id];
                            setTimeout(() => {
                                ele.loadingURL = false;
                            }, 2000);
                        }
                    });

                    this.selectedVideo.template_info?.contents
                        ?.filter((c) => c.content == 'clip')
                        .forEach((ele) => {
                            ele.loadingUrl = false;
                            ele.url = output.filter((a) => a[ele.source])[0][ele.source];
                            setTimeout(() => {
                                ele.loadingUrl = false;
                            }, 2000);
                        });
                });
                resolve({ type: 'success' });
            } catch (e) {
                reject(e);
            }
        });
    }

    loadVideoThumbImage(email, session_id, video: video): Promise<any> {
        return new Promise(async (resolve, reject) => {
            this.dataservice.getThumbBatchSignedURL(email, session_id, [this.selectedVideo.fpath]).then((res) => {
                let output = res['output'];
                // console.log("output thumb here ", output);

                if (this.selectedVideo.thumb) {

                    this.selectedVideo.thumbnail = output.filter(
                        (a) => a.fpath == this.selectedVideo.fpath,
                    )[0]?.thumb_signed_url;

                    // console.log(" this.selectedVideo.sap_video_url_thumb", this.selectedVideo);
                }
                resolve({ type: "success" });
            });
        });

    }
    loadSelectedVideoData(email: string, session: string): Promise<boolean> {
        // console.log("Load selected Video Data...") ;
        return new Promise((resolve, reject) => {
            this.loader.showLoader();
            this.intiateVideoDataLoading(email, session, this.selectedVideo)
                .then((res) => {
                    this.loader.hideLoader();
                    if (this.selectedVideo?.sap_video_id && this.selectedVideo?.sap_video_id != '') {
                        this.oRouter.navigate(['mv/timeline-view']);
                    } else {
                        this.oRouter.navigate(['wizard/timeline-view']);
                    }
                    return resolve(true);
                })
                .catch((e) => {
                    this.loader.hideLoader();
                    if (this.selectedVideo?.sap_video_id && this.selectedVideo?.sap_video_id != '') {
                        this.oRouter.navigate(['mv/timeline-view']);
                    } else {
                        this.oRouter.navigate(['wizard/timeline-view']);
                    }
                    return reject();
                });
        });
    }

    public changeTemplateForClip(template: template) {
        this.templateVideoDurationLimit = 0;
            if (template) {
                this.dataservice
                    .getTemplateContent(template.template_name, 'clip', this.selectedVideo?.duration)
                    .then((res) => {
                        let tempData = [];
                        let templateVideos = res['template_data'];
                        templateVideos.map((data)=>{
                            if(!data.parent){
                                tempData.push(data);
                            }
                        })
                        this.templateVideoLimit = tempData.length;
                        templateVideos.map((data)=>{
                            if (data.ts[1] > this.templateVideoDurationLimit )
                         {
                            this.templateVideoDurationLimit = data.ts[1];
                         }

                    })
                     })
                     .catch((err) => {
                        // console.log(err);
                        this.errorService.handleError(err.error ? err.error : err, 'Content Generation Failed', true);
                    });
            }

    }
}
