import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { CreateVideoManagerService } from './../../../Managers/create-video-manager.service';
import { OnboardingManagerService } from './../../../Managers/onboarding-manager.service';
import { VideoManagerService } from './../../../Managers/video-manager.service';
import { cDataService } from './../../../services/data.service';
import { cSessionService } from './../../../services/session.service';

@Component({
  selector: 'app-location-videos',
  templateUrl: './location-videos.component.html',
  styleUrls: ['./location-videos.component.scss']
})
export class LocationVideosComponent implements OnInit {

  bDraftLoader : boolean = true;
  oSetTimeOut: any;
  sVideoToSearch : string="";
  public title:string="";
  search_term: string="";
  constructor(private oSessionService : cSessionService,public onBoardingManager:OnboardingManagerService,private oRouter : Router,public videoManager:VideoManagerService, private oDataService : cDataService,public createVideoManager:CreateVideoManagerService)
  {

  }
  ngOnDestroy()
  {

  }
  ngOnInit()
  {
    this.oSessionService.dashboardVideoListView = false;
    this.oSessionService.dashboardVideoGridView = true;
    this.DraftVideosComponent_SetTimeOut();
  }
  DraftVideosComponent_SetTimeOut()
  {
    this.oSetTimeOut=setTimeout(() =>
    {
      this.bDraftLoader=false;
    }, 10000);
  }
  DraftVideosComponent_NavigateToCreateVideo()
  {
    this.createVideoManager.selectedVideo = undefined;
    this.oRouter.navigate(['wizard/overview']);
  }



  SearchVideo()
  {
    this.title = this.title.replace(/^\s+|\s+$/g, '');
    this.videoManager.search.emit(this.title);
    this.search_term = this.title;
  }


clearSearchTerm() {
  this.search_term = "";
  this.title = "";
  this.videoManager.search.emit(this.title);
}

}
